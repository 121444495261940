var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "destinations-wrapper" },
    [
      _c("span", { staticClass: "destination-title py-2" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      !_vm.destinations.length && !_vm.destinationsLoading
        ? _c("div", { staticClass: "information bold mt-2 mb-6" }, [
            _vm._v(_vm._s(_vm.$t("destinations.connectingXperstreamStudio"))),
          ])
        : _vm._e(),
      _vm.destinationsLoading || _vm.destinations.length
        ? _c(
            "div",
            { staticClass: "py-2" },
            [
              _c("AppList", {
                attrs: {
                  listItems: _vm.destinations,
                  actions: _vm.actions,
                  loading: _vm.destinationsLoading,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("AddDestination"),
      !_vm.destinations.length && !_vm.destinationsLoading
        ? _c("div", { staticClass: "mt-6 information" }, [
            _c("span", [
              _vm._v(
                " " + _vm._s(_vm.$t("destinations.wantAddDestinationNow")) + " "
              ),
            ]),
            _c(
              "span",
              {
                staticClass: "try ml-3 cursor-pointer",
                attrs: { "data-test-id": "dsh-dst-try-brd" },
                on: { click: _vm.showAddBroadcastModal },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("destinations.tryATestBroadcast")) + " "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }