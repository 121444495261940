<template>
  <div class="destinations-wrapper">
    <span class="destination-title py-2">{{ title }}</span>
    <div v-if="!destinations.length && !destinationsLoading" class="information bold mt-2 mb-6">{{ $t('destinations.connectingXperstreamStudio') }}</div>
    <div v-if="destinationsLoading || destinations.length" class="py-2">
      <AppList :listItems="destinations" :actions="actions" :loading="destinationsLoading" />
    </div>
    <AddDestination />
    <div v-if="!destinations.length && !destinationsLoading" class="mt-6 information">
      <span>
        {{ $t('destinations.wantAddDestinationNow') }}
      </span>
      <span class="try ml-3 cursor-pointer" data-test-id="dsh-dst-try-brd" @click="showAddBroadcastModal">
        {{ $t('destinations.tryATestBroadcast') }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import AddEditBroadcastModal from '@/components/Modals/AddEditBroadcastModal';
import AppList from '@/components/Common/AppList';
import AddDestination from '@/components/AddDestination';
import { DESTINATIONS } from '@/constants/modules';

export default {
  name: 'Destinations',
  components: { AppList, AddDestination },
  computed: {
    ...mapState('Destinations', ['destinations', 'destinationsLoading']),
    title() {
      return this.destinations.length ? this.$t('destinations.myDestinations') : this.$t('destinations.addDestinations');
    },
    actions() {
      return {
        childs: [
          {
            title: this.$t('destinations.deleteDestination'),
            icon: 'trash',
            color: 'var(--v-error-base)',
            onClick: (item) => {
              this.deleteDestination(item.id);
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions(DESTINATIONS, ['deleteDestination']),
    showAddBroadcastModal() {
      const modal = this.$showModal(
        AddEditBroadcastModal,
        {},
        {
          completed: (sessionId) => {
            modal.close();
            this.$router.push(`/prejoin/${sessionId}`);
          },
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.select-destination {
  cursor: pointer;
}
.destinations-wrapper {
  .destination-title {
    font-size: 17px;
    font-weight: 400;
    color: var(--v-text-gray-base);
    line-height: 20px;
  }
  .add-destination {
    font-size: 15px;
    font-weight: 400;
    color: var(--v-text-gray-base);
    line-height: 15px;
    cursor: pointer;
  }

  .information {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: var(--v-dark-gray-base);
    opacity: 0.5;
    &.bold {
      font-weight: 700;
    }
    .try {
      color: var(--v-white-base);
      cursor: pointer;
    }
  }
}
</style>
